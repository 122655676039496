@import "variables.css";


.questionTable-container {
    font-family: 'Noto Sans', sans-serif;
}

.questionTable-container .MuiFormControl-root .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-color-1);
    border-width: 1px;
}

.questionTable-container .MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root fieldset {
    border-color: rgb(50, 86, 60, 29%);
    border-radius: 5px;
}

.questionTable-container .MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root:hover fieldset {
    border-color: #9ABEBC;
}


.questionTable .MuiTableHead-root {
    height: 30px;
}

.questionTable .MuiTableRow-head .MuiTableCell-head {
    background-color: var(--blue-900);
    font-weight: 700;
    font-size: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
}

.questionTable .MuiTableRow-head .MuiTableCell-head, .questionTable .MuiTableRow-head .MuiSvgIcon-root {
    color: #fff !important;
}

.questionTable .MuiTableCell-body {
    padding: 1rem .5rem;
    border-bottom: 1px solid #ADC4CE;
    max-width: 500px;
}

.questionTable .MuiTableRow-root {
    cursor: default;
}

.questionTable .MuiTableRow-root.Mui-selected, .questionTable .MuiTableRow-root.Mui-selected:hover {
    background-color: rgba(154, 190, 188, 0.3);
}


.questionTable .MuiTableCell-alignCenter .MuiTypography-root {
    justify-content: center;
}


.questionTable .MuiTableRow-root .MuiTableCell-root.MuiTableCell-paddingCheckbox {
    padding: 0 2rem;
}

.questionTable .MuiTableCell-root.MuiTableCell-body .MuiCheckbox-root {
}

.questionTable .MuiTableCell-root.MuiTableCell-body .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
    color: var(--primary-color);
}

.questionTable .MuiIconButton-root {
    border: 1px solid #808080;
    width: 32px;
    height: 32px;
}

.questionTable .MuiIconButton-root:hover {
    border-color: #1F4172;
}

.tableToolbar {
    padding-top: 5px;
}

.tableToolbar .MuiFormLabel-root {
    top: -8px;
}

.tableToolbar .MuiInputBase-input.MuiSelect-select {
    padding-top: 7px;
    padding-bottom: 7px;
}

.tablePagination.MuiPagination-root {
    padding: .5rem 1rem;
}

.tablePagination.MuiPagination-root .MuiPagination-ul {
    justify-content: center;
}



