:root {
    --primary-color: #386641;
    --primary-color-1: #6A994E;
    --primary-color-2: #A7C957;
    --secondary-color: #F2E8CF;
    --accent-color: #BC4749;

    --blue-900: #1F4172;
    --baby-blue: #ADC4CE;
}
