@tailwind base;
@tailwind components;
@tailwind utilities;
@import "variables.css";

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "Noto Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Roboto Serif", serif;
}

/* Rotating Earth Style  */
#earthback {
    display: flex;
    justify-content: center;
    align-items: center;
}

#earth {
    /* [1] Allow it to contain the absolutely positions pseudo-elements (later-on) */
    position: relative;
    background-image: url("https://lh5.googleusercontent.com/-kkxEx-SkRaY/VBLF4BV2lZI/AAAAAAAAKao/FnKsv7402_c/s500/earthmap.jpg");
    background-size: 160% 100%; /* Set the size to 80% of the container */
    background-position: center center; /* [2] Set-up the dimensions and spacing */
    /* height: 0; */
    padding: 30%;
    /* [3] Prepare the animation effects */
    transition: transform 200ms linear;
    animation: rotate 35s linear infinite; /* This is going to be defined in the next step */
    box-shadow: 0 0 30px rgba(28, 47, 56, 0.64);
    /* [4] Tweak the appearance, and give it a nice background i.e. the world map */
    border-radius: 50%;
    /* background: url("https://cdn.pixabay.com/photo/2012/02/22/19/28/africa-15428_960_720.jpg")
      0 0 repeat / 630px; */

    /* [5] Position things in a 3d space */
    transform-style: preserve-3d;
}

#earth:after {
    /* [1] Break the flow to show this as an overlay */
    position: absolute;
    top: 0;
    left: 0;

    /* [2] Make it take all the space available in the box (ahem... globe!) */
    width: 100%;
    height: 100%;

    /* [3] Make sure this has no generated content, as we want this just for fancy purposes */
    content: "";

    /* [4] Give it some shape and shadow */
    border-radius: 50%;
    box-shadow: -40px 5px 60px 10px rgb(11, 17, 18) inset;
}

.filtered-image {
    /* brightness(1.3) contrast(1.2) sepia(0.2) hue-rotate(90deg) */
    filter: brightness(1.05) contrast(1.3) saturate(3) grayscale(0.2) sepia(0.2);
}

#earth:before {
    /* [1] Again, break the flow to show this as an overlay */
    position: absolute;
    top: 0;
    left: 0;
    /* [2] Again, give it all the available space */
    width: 100%;
    height: 100%;

    /* [3] Duh. */
    content: "";

    /* [4] Add some shape and overlay effect to it */
    opacity: 0.2;
    border-radius: 50%;
    background: radial-gradient(circle at 100px 100px, #ffffff, #000);
}

.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
    color: var(--primary-color-1);
}

.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--primary-color-1);
}

.MuiTooltip-tooltip {
    white-space: break-spaces;
}

@keyframes rotate {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 630px 0;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
