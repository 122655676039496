.responsive-image{
    width: 30%;
    height: 55%;
   
}
@media screen and (max-width:700px) {
    .responsive-image{
        width: 25%;
        height: 55%;
     
    }
}